import React from "react";
import logo from "./logo.svg";
import "./App.css";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <a className="App-link" href="#" rel="noopener noreferrer">
                    Demo Main
                </a>
                <img src={logo} className="App-logo" alt="logo" />
                <p>Main Auth to Subdomains</p>
                <a
                    className="App-link"
                    href="https://admin.tapacenterdemo.com"
                    rel="noopener noreferrer"
                >
                    Admin
                </a>
                <a
                    className="App-link"
                    href="https://control.tapacenterdemo.com"
                    rel="noopener noreferrer"
                >
                    Control
                </a>
                <a
                    className="App-link"
                    href="https://manage.tapacenterdemo.com"
                    rel="noopener noreferrer"
                >
                    Manage
                </a>
            </header>
        </div>
    );
}

export default App;
